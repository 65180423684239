.ADA { color: #3CC8C8; }
.ADC { color: #3CB0E5; }
.AEON { color: #164450; }
.AMP { color: #048DD2; }
.ANC { color: #000; }
.ARCH { color: #002652; }
.ARDR { color: #1162a1; }
.ARK { color: #F70000; }
.AST { color: #18F18F; }
.AUR { color: #136c5e; }
.BANX { color: #225BA6; }
.BAT { color: #9e1f63; }
.BAY { color: #584ba1; }
.BC { color: #202121; }
.BCN { color: #964F51; }
.BCY { color: #123A5A; }
.BELA { color: #009EF9; }
.BFT { color: #4fc3f7; }
.BLZ { color: #6BE6BE }
.BRK { color: #194fa0; }
.BRX { color: #a8c300; }
.BSD { color: #1186E7; }
.BTA { color: #210094; }
.BTC, .BCH { color: #F7931A; }
.BTG { color: #eba809; }
.BTCD { color: #2A72DC; }
.BTCP { color: #272D63; }
.BTM { color: #9FA8B4; }
.BTS { color: #03A9E0; }
.BURST { color: #2D2D2D; }
.CLAM { color: #D6AB31; }
.CLOAK { color: #DF3F1E; }
.CVC { color: #3AB03E }
.DAO { color: #FF3B3B; }
.DASH { color: #1c75bc; }
.DCR { color: #3b7cfb; }
.DCT { color: #008770; }
.DGB { color: #0066cc; }
.DGD, .DGX { color: #D8A24A; }
.DMD { color: #5497b2; }
.DOGE { color: #BA9F33; }
.EMC { color: #674c8c; }
.EMC2 { color: #00CCFF; }
.EOS { color: #19191A; }
.ERC { color: #101E84; }
.ETC { color: #669073; }
.ETH { color: #282828; }
.EXP { color: #FFAA5C; }
.FC2 { color: #040405; }
.FCT { color: #2175BB; }
.FLDC { color: #C40E09; }
.FLO { color: #1358C8; }
.FRK { color: #0633cd; }
.FTC { color: #679EF1; }
.FUN { color: #EF3A5C; }
.GAME { color: #ed1b24; }
.GBYTE { color: #2C3E50; }
.GDC { color: #E9A226; }
.GEMZ { color: #e86060; }
.GLD { color: #E8BE24; }
.GNO { color: #00A6C4; }
.GNT { color: #00d6e3; }
.GOLOS { color: #2670B7; }
.GRC { color: #88A13C; }
.GRS { color: #648FA0; }
.HEAT { color: #ff5606; }
.HUC { color: #FFC018; }
.ICN { color: #4c6f8c; }
.ICX { color: #22C8CC; }
.IFC { color: #ed272d; }
.INCNT { color: #f2932f; }
.IOC { color: #2fa3de; }
.IOTA { color: #FFFFFF; }
.JBS { color: #1A8BCD; }
.KMD { color: #326464; }
.KOBO { color: #80C342; }
.KORE { color: #DF4124; }
.LBC { color: #015C47; }
.LDOGE { color: #ffcc00; }
.LSK { color: #0D4EA0; }
.LTC { color: #838383; }
.MAID { color: #5492D6; }
.MARKS { color: #504C4C; }
.MCO { color: #0D3459; }
.MINT { color: #006835; }
.MONA { color: #a99364; }
.MRC { color: #4279bd; }
.MSC { color: #1D4983; }
.MTL { color: #242424; }
.MTR { color: #b92429; }
.MUE { color: #f5a10e; }
.NAV { color: #7D59B5; }
.MYST { color: #f5a10e; }
.NBT { color: #FFC93D; }
.NEO { color: #58BF00; }
.NEOS { color: #1d1d1b; }
.NEU { color: #2983c0; }
.NLG { color: #003E7E; }
.NMC { color: #6787B7; }
.NMR { color: #8DE8DE; }
.NOTE { color: #42daff; }
.NVC { color: #ecab41; }
.NXT { color: #008FBB; }
.OK { color: #0165A4; }
.OMG { color: #1A53F0; }
.OMNI { color: #18347E; }
.OPAL { color: #7193AA; }
.PART { color: #05D5A3; }
.PASC { color: #F7931E; }
.PIGGY { color: #F27A7A; }
.PINK { color: #ED31CA; }
.PIVX { color: #3b2f4d; }
.POT { color: #105B2F; }
.PPC { color: #3FA30C; }
.PPT { color: #5a9ef6; }
.QRK { color: #22AABF; }
.QTUM { color: #359BCE; }
.RADS { color: #924cea; }
.RBIES { color: #C62436; }
.RBT { color: #0d4982; }
.RBY { color: #D31F26; }
.RDD { color: #ED1C24; }
.REP { color: #40a2cb; }
.RIC { color: #60E4DD; }
.RHOC { color: #C26C26; }
.RISE { color: #43CEA2; }
.SALT { color: #373C43; }
.SAR { color: #1B72B8; }
.SCOT { color: #3498DB; }
.SDC { color: #981D2D; }
.SIA { color: #00CBA0; }
.SJCX { color: #003366; }
.SLG { color: #5A6875; }
.SLS { color: #1EB549; }
.SNRG { color: #160363; }
.START { color: #01AEF0; }
.STEEM { color: #1A5099; }
.STRAT { color: #2398dd; }
.SWIFT { color: #428BCA; }
.SYNC { color: #008DD2; }
.SYS { color: #0098DA; }
.TRIG { color: #1fbff4; }
.TRX { color: #c62734; }
.TX { color: #1F8BCC; }
.UBQ { color: #00ec8d; }
.UNITY { color: #ED8527; }
.USDT { color: #2CA07A; }
.VEN { color: #15BDFF; }
.VIA { color: #565656; }
.VIOR { color: #1F52A4; }
.VNL { color: #404249; }
.VPN { color: #589700; }
.VRC { color: #418bca; }
.VTC { color: #1b5c2e; }
.WAVES { color: #24aad6; }
.XAI { color: #2ef99f; }
.XBC { color: #F7931A; }
.XBS { color: #d3261d; }
.XCP { color: #EC1550; }
.XEM { color: #41bf76; }
.XLM { color: #08B5E5; }
.XMR { color: #FF6600; }
.XMY { color: #BC3182; }
.XPM { color: #e5b625; }
.XRP { color: #346AA9; }
.XTZ { color: #A6DF00; }
.XVC { color: #B50126; }
.XVG { color: #42AFB2; }
.XZC { color: #23B852; }
.YBC { color: #D6C154; }
.ZEC { color: #e5a93d; }
.ZEIT { color: #ACACAC; }
.ZRX { color: #302C2C; }
